<template>
  <div class="container">
    <poster :poster-list="posterList" :page-path="pagePath" :module-key="moduleKey" :time-format="3" :token="token" />
  </div>
</template>

<script>
import Poster from '../../../components/Poster/Index'

export default {
  name: "NewYearPoster",
  components: {
    Poster
  },
  data() {
    return {
      token: this.$route.query.token,
      posterList: [
        {
          backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220523180654728.png',
          tempBgImage: null,
          miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220523180626126.png',
          textColor: '#fff',
          tempPoster: '',
          textPoint: [430, 1204],
          wxCode: { width: 188, point: [58, 1131] }
        },
        {
          backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220523180704922.png',
          tempBgImage: null,
          miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220523180640726.png',
          textColor: '#fff',
          tempPoster: '',
           textPoint: [430, 1204],
          wxCode: { width: 188, point: [58, 1131] }
        },
        {
          backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220721113846165.png',
          tempBgImage: null,
          miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220721113825171.png',
          textColor: '#fff',
          tempPoster: '',
           textPoint: [430, 1204],
          wxCode: { width: 188, point: [58, 1131] }
        },
        {
          backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220721113915113.png',
          tempBgImage: null,
          miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20220721113857969.png',
          textColor: '#fff',
          tempPoster: '',
           textPoint: [430, 1204],
          wxCode: { width: 188, point: [58, 1131] }
        },
      ],
      pagePath: this.baseHost + '/',
      moduleKey: ''
    }
  },
  created() {
    this.moduleKey = this.token ? '86fab1eb-d227-441d-9d4e-2c6c5cd6272b' : 'gzhFJScx202206'
  }
}
</script>

<style scoped>
</style>